import React from 'react'
import {Grid, GridItem, SimpleGrid} from '@chakra-ui/react'
import ProjectHeader from '../../components/Header/ProjectHeader'
import DesktopVid from '../../video/hfm-desktop.mp4'
import MobileVid from '../../video/hfm-mobile.mp4'
import ChatVid from '../../video/hfm-chat.mp4'
import LoveVid from '../../video/love.mp4'
import Give20 from '../../video/give20.mp4'
import Myths3 from '../../video/3myths.mp4'
import EmailVid from '../../images/hf/email-scroll.mp4'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import SilentPlayer from '../../components/video/SilentPlayer'
import { xgap, mypx, col2} from '../../constants/spacing'
import Insta from '../../images/hf/insta.mp4'
import hfemail from '../../images/hf/hf-email.mp4'
import epemail from '../../images/hf/ep-email.mp4'
import SEO from '../../components/SEO'
import ProjectArrows, { ProjectLinkers } from '../../components/Header/ProjectArrows'
const preventClick = (e) => e.preventDefault()

const Hfm = ({ data, location }) => {
    const dat = data.wpProject.hollandProjects
    const img1 = getImage(dat.image1.localFile);
    const img2 = getImage(dat.image2.localFile);
    const img3 = getImage(dat.image3.localFile);
    const img4 = getImage(dat.image4.localFile);
    const img5 = getImage(dat.image5.localFile);
    const img6 = getImage(dat.image6.localFile);
    const img7 = getImage(dat.image7.localFile);
    const img8 = getImage(dat.image8.localFile);
    const img9 = getImage(dat.image9.localFile);
    const img10 = getImage(dat.image10.localFile);
    const img11 = getImage(dat.image11.localFile);
    const img12 = getImage(dat.image12.localFile);
    const img13 = getImage(dat.image13.localFile);
    const img14 = getImage(dat.image14.localFile);
    const img15 = getImage(dat.image15.localFile);
    const img16 = getImage(dat.image16.localFile);
    const img17 = getImage(dat.image17.localFile);

    return (
        <>
         <SEO title={dat.projecttitle} description={dat.projectdescription} image={img1} url="https://hollandhames.com/project/hellofresh-marketing"/>
        <ProjectHeader title1="HELLOFRESH" title2="MARKETING" description={dat.projectdescription} desktopVid={DesktopVid} mobileVid={MobileVid} />
        <SimpleGrid pb={xgap} py={mypx} gap={xgap} columns={1}>
          <SimpleGrid  gap={xgap} columns={1} px={mypx}>
            <SimpleGrid columns={col2} gap={xgap}>
                <SimpleGrid columns={1} gap={xgap}>
                <GatsbyImage onContextMenu={preventClick} image={img1} alt={dat.image1.altText}/>
                <SilentPlayer url={EmailVid} />
                </SimpleGrid>
                <SimpleGrid columns={1} gap={xgap}>
                    <GatsbyImage onContextMenu={preventClick} image={img2} alt={dat.image2.altText} />
                    <GatsbyImage onContextMenu={preventClick} image={img4} alt={dat.image4.altText} />
                </SimpleGrid>
            </SimpleGrid>
            <SimpleGrid columns={1} gap={xgap}>
                <GatsbyImage onContextMenu={preventClick} image={img5} alt={dat.image5.altText} />
            </SimpleGrid>
            <SimpleGrid columns={col2} gap={xgap}>
                <GatsbyImage onContextMenu={preventClick} image={img6} alt={dat.image6.altText} />
                <SilentPlayer  url={ChatVid} />
            </SimpleGrid>
            </SimpleGrid>
            <SimpleGrid bg="orange" columns={1} gap={xgap}  pt="10%" pb="10%" px={mypx}>
            <SimpleGrid columns={1} gap={xgap}>
                <SilentPlayer url={Myths3} />
            </SimpleGrid>
            <SimpleGrid columns={col2} gap={xgap}>
                <GatsbyImage onContextMenu={preventClick} image={img7} alt={dat.image7.altText}/>
                <GatsbyImage onContextMenu={preventClick} image={img8} alt={dat.image8.altText}/>
            </SimpleGrid>
            <SimpleGrid columns={1}>
                <GatsbyImage onContextMenu={preventClick} image={img9} alt={dat.image9.altText} />
            </SimpleGrid>
            <Grid gap={xgap} templateColumns={["repeat(1, 1fr)", null,null,null,"repeat(2, 1fr)"]} templateRows={["(2, 1fr)", null,null,null,"(5, 1fr)"]}>
                <GridItem  colStart={1} colEnd={1} rowSpan={[1,null,null,null,5]}>
                <SimpleGrid  columns={1} gap={xgap}> 
                <SilentPlayer url={Give20} />
                <SilentPlayer url={LoveVid} />
                </SimpleGrid>
                </GridItem>
                <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[2,null,null,null,2]} rowEnd={[2,null,null,null,4]}>
                <SimpleGrid  columns={1} gap={xgap}> 
                    {/* <GatsbyImage onContextMenu={preventClick} image={img3} alt={dat.image3.altText} /> */}
                    <SilentPlayer url={epemail} />
                    </SimpleGrid>
                </GridItem>
            </Grid>
            {/* <SimpleGrid columns={col2} gap={xgap}>
                <SimpleGrid maxHeight="lg" columns={1} gap={xgap}> 
                <SilentPlayer url={Give20} />
                <SilentPlayer url={LoveVid} />
                </SimpleGrid>
                <SimpleGrid maxHeight="lg" columns={1} gap={xgap}> 
                    <GatsbyImage onContextMenu={preventClick} image={img3} alt={dat.image3.altText} />
                    </SimpleGrid>
            </SimpleGrid> */}
        </SimpleGrid>
        <SimpleGrid  gap={xgap} columns={1} px={mypx}>
        <SimpleGrid columns={col2} gap={xgap}>
            <GatsbyImage onContextMenu={preventClick} image={img10} alt={dat.image10.altText} />
            <GatsbyImage onContextMenu={preventClick} image={img17} alt={dat.image17.altText} />
        </SimpleGrid>
        <SimpleGrid columns={1} gap={xgap}>
            <GatsbyImage onContextMenu={preventClick} image={img11} alt={dat.image11.altText} />
        </SimpleGrid>
        <Grid gap={xgap} flex={true} templateRows={["(3, 1fr)",null,null,null,"(10,1fr)"]} templateColumns={["repeat(1, 1fr)", null,null,null,"repeat(2, 1fr)"]}>
            <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={[1,null,null,null,3]}>
                <GatsbyImage onContextMenu={preventClick} image={img12} alt={dat.image12.altText} />
            </GridItem>
            <GridItem  colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,1]} rowStart={[3,null,null,null,4]} rowEnd={[3,null,null,null,6]}>
                <GatsbyImage onContextMenu={preventClick} image={img13} alt={dat.image13.altText} />
            </GridItem>
            {/* <GridItem  colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[2,null,null,null,1]} rowEnd={[2,null,null,null,5]}>
                <SilentPlayer url={EmailVid} />
            </GridItem>
            <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[5,null,null,null,6]} rowEnd={[5,null,null,null,10]}>
                <SilentPlayer url={EmailVid} />
            </GridItem> */}
            <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[2,null,null,null,1]} rowEnd={[2,null,null,null,10]}>
          
                <SimpleGrid columns={1} gap={xgap}>
                    <SilentPlayer url={Insta} />
                    <SilentPlayer url={hfemail} />
                </SimpleGrid>
         
            </GridItem>
            <GridItem colStart={[1,null,null,null,1]} colEnd={[1,null,null,null,1]} rowStart={[4,null,null,null,7]} rowEnd={[4,null,null,null,9]}>
                <GatsbyImage onContextMenu={preventClick} image={img14} alt={dat.image14.altText} />
            </GridItem>
            </Grid>
            <SimpleGrid columns={1} gap={xgap}>
                <GatsbyImage onContextMenu={preventClick} image={img15} alt={dat.image15.altText} />
            </SimpleGrid>
            <SimpleGrid columns={1} gap={xgap}>
                <GatsbyImage onContextMenu={preventClick} image={img16} alt={dat.image16.altText} />
            </SimpleGrid>
            </SimpleGrid>
        </SimpleGrid>

        <ProjectLinkers prevTitle='Hellofresh Packaging' prevUrl='/project/hellofresh-packaging' nextTitle='NFL Hall of Fame' nextUrl='/project/nfl' />
     
        </>
    )
}

export default Hfm

export const hfmquery = graphql`
  {
    wpProject(title: { eq: "Hellofresh Marketing" }) {
      hollandProjects {
        projecttitle
        projectdescription
        projectcategories
        image1 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED )
            }
          }
        }
        image2 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image3 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image4 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                  
                  placeholder: TRACED_SVG)
            }
          }
        }
        image5 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image6 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image7 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image8 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image9 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image10 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image11 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image12 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image13 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image14 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image15 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image16 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image17 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        image18 {
            altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`;